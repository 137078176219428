<template>
  <div class="modal">
    <div class="block fullWidth blue" style="line-height:30px;">
      <div>
        <label style="margin-left:20px;">
          {{title}}
        </label>
        <button class="btnClose right" @click="cerrar()">
          <i class="bi bi-x-square-fill"></i>
        </button>
      </div>      
      
    </div>
    <div class="block col90 center" 
    style="
    min-height:70vh;
    max-height:80vh;
    
    padding:10px;">
      <slot></slot>
    </div>
    <!--
      overflow-y:auto;
      -->
    
  </div>
</template>

<script>
export default {
  name: 'Modal',
  methods:{

    cerrar(){

      this.$emit('closeModalEv');

    }

  },
  props:{
    title:String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
