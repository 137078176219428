<template>
  <div class="fullWidth" style="padding-top:0px;max-height:100vh;overflow:hidden;margin-top:-30px;">
    
    <div class="fullWidth" style="margin-top:0px;">

      <div :class="'fullWidth tCenter '" style="background:white;vertical-align:middle;padding-bottom:0px;border-bottom: solid 10px var(--orange);margin-top:0px;overflow:hidden;">      
        <div class="fullWidth colSm50 row rowm tCenter"  style="vertical-align:top;height:10vh;margin-top:30px;">
          
          <div class="col95 colSm95 center" style="color:black;">
            
            <div class="col95 center row tLeft" style="padding:20px;">

              <label class="col50 tCenter row">
                <strong>
                  Nombre:
                </strong>
                <strong style="font-family:fedrasanslight;color:var(--orange);">
                  {{userStore.nombre+' '+userStore.ap1+' '+userStore.ap2}}
                </strong>
              </label>              

            
              
              <label class="col40 tCenter row">
                <strong>
                  CURP:
                </strong>
                <strong style="font-family:fedrasanslight;color:var(--orange);">
                  {{userStore.curp}}                
                </strong>
              </label>

              <div class="col10 btnOrange tCenter row" @click="toHome()">
                <strong>
                  <i class="bi bi-arrow-bar-left"></i>
                  Salir
                </strong>
                
              </div>
              

            </div>
            

            

          </div>
        </div>
      </div>

      <div class="fullWidth tCenter" style="background:none;overflow-y:auto;max-height:70vh;height:100vh;">
        
        <article class="fullWidth center" style="background:none;overflow:hidden;margin-top:10px;">
          <div class="glassCard col95 center tCenter" style="margin-bottom:25px;min-height:50px;">
            <div @click="showMisCursos=!showMisCursos;showMisCursos==true?this.getListaConstanciasAlumno():'';" style="cursor:pointer;">
              <label class="subtitle" style="font-family: town;color:var(--orange);">
                <strong>
                  MIS CONSTANCIAS
                </strong>
              </label>
              <i class="bi bi-chevron-down" style="color:black;" v-if="!showMisCursos"></i>
              <i class="bi bi-chevron-up" style="color:black;" v-else></i>
            </div>

            <article :class="!showMisCursos?'fromFullto070':'from0toFull70'">
              <div class="center" v-if="showMisCursos">
                <div class="fullWidth tCenter row" style="vertical-align:top;">
                  <div class="col40 row tRight">
                    <div class="col85 row">
                      <input class="input" type="text" v-model="busqueda" style="line-height: 31px;" placeholder="Buscar Curso">
                    </div>
                    <div class="col5 row tCenter" style="background:var(--blue);border-top-right-radius: 5px;border-bottom-right-radius: 5px;line-height: 35px;">
                      <i class="bi bi-search"></i>
                    </div>
                  </div>
                </div>
                
              </div>
              <div style="text-align:left;max-height:60vh;height:70vh;overflow-y:auto;overflow-x:hidden;" v-if="showMisCursos">
                <!--
                  <div style="color:black;">
                    {{userStore}}
                  </div>
                -->
                <CartaCursoInscrito v-for="curso in filtroCursos" :key="curso" :cursoData="curso" @setSelectedCursoEv="setSelectedCurso(curso)">
                </CartaCursoInscrito>
                <div style="min-height:50px;">
                  <span></span>
                </div>
              </div>
            </article>

          </div>

        </article>
        <!--
        <article class="col95 center tLeft" style="background:none;overflow-x:hidden;">

          <div class="glassCard fullWidth center tCenter" style="margin-bottom:10px;min-height:50px;">
            <div>
              <label class="subtitle" style="font-family: town;color:var(--orange);">
                <strong>
                  CURSOS QUE TE PUEDEN INTERESAR
                </strong>
              </label>              
            </div>
          </div>

          <div class="cursoContainer fullWidth center tCenter">
            <div class="cursoBtn" v-for="imagen in listaPromos" :key="imagen">
              <div class="cursoBtn-inner">
                <img class="cursoBtn-front" :src="imagen" 
                style="object-fit:contain;width:100%;">
                <div class="cursoBtn-back" style="position:absolute;top:0;width:100%;height:100%;">
                  <button class="btnOrange col80 center" style="position:absolute;top:40%;left:10%;">
                    Ver Curso
                  </button>
                </div>              
              </div>
            </div>
          </div>
        </article>
        -->
        
      </div>


        

      

    </div>

    <modal title="Registro" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;overflow-y:auto;">

        <h3 style="color:black;">

          {{modalMessage}}
          
        </h3>
        
      </div>      
    </modal>

    

    <!--
    <VistaCurso :cursoID="userStore.selectedCursoInscripcion.c0" v-if="showVistaCurso" @cerrarVistaCursoEv="showVistaCurso=false;">
    </VistaCurso>
    -->
  </div>
</template>

<script>

import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
/*
import InputText from '.https://webdevworks.xyz/liceo/portalcursos/Inputs/InputText.vue';
import InputSelect from '.https://webdevworks.xyz/liceo/portalcursos/Inputs/InputSelect.vue';
*/

//import InputText from '../Inputs/InputText.vue';
import Modal from '../Modal/Modal.vue';
import CartaCursoInscrito from './CartaCursoInscrito.vue';

//import {data} from './cursos';

export default {
  name: 'Home',
  components:{
    Modal,
    CartaCursoInscrito,
    //VistaCurso,
    //InputText
  },
  watch:{    


  },
  mounted:function(){
    
    this.userStore.currentView='HOME';

    //this.listaCursos=data.getData();
    //this.listaHorarios=data.getHorariosData();
    //console.log(this.listaCursos);

    this.getListaConstanciasAlumno();

    //this.getListaPromos();
    this.plantel=this.userStore.plantel;
    if(this.userStore.idAlCur==''){
      this.userStore.login=0;
      this.userStore.currentView='LOGIN';
      this.$root.changeRoute("/");
    }
    //this.userStore.login=1;

  },
  data:function(){
    return{

      showMisCursos:false,
      showMisCursosActuales:false,
      showMisCursosCursados:false,

      showCursosGenerales:false,

      imgSrc:'',
      imgIndex:0,
      mode:1,
      busqueda:'',
      showVistaCurso:false,
      showModal:false,
      modalMessage:'',
      plantel:'',
      categoria:'',
      modalidad:'',
      duracion:'',
      nCursos:0,
      cursoSeleccionado:{},
      listaPromos:[],
      listaPlanteles:{
        'XALAPA':{
          categorias:{
            belleza:true,
            bienestar:true,
            ciencias:true,
            contabilidad:true,
            cuidadoPersonal:true,
            examen:true,
            diseno:true,
            gastronomia:true,
            idiomas:true,
            informatica:true,
            modas:true,
            manualidades:true,
            matematicas:false,
            sutentabilidad:false
          }
        },
        'VERACRUZ':{
          categorias:{
            belleza:true,
            bienestar:true,
            ciencias:true,
            contabilidad:true,
            cuidadoPersonal:true,
            examen:true,
            diseno:true,
            gastronomia:true,
            idiomas:true,
            informatica:true,
            modas:true,
            manualidades:true,
            matematicas:true,
            sutentabilidad:true
          }
        },
        'VILLAHERMOSA':{
          categorias:{
            belleza:true,
            bienestar:true,
            ciencias:true,
            contabilidad:true,
            cuidadoPersonal:true,
            examen:true,
            diseno:true,
            gastronomia:true,
            idiomas:true,
            informatica:true,
            modas:true,
            manualidades:true,
            matematicas:true,
            sutentabilidad:true
          }
        }
      },
      listaModalidad:[{c0:'ID',c1:'PRESENCIAL'},{c0:'ID',c1:'EN LÍNEA'}],
      listaDuracion:[
        {c0:'ID',c1:'1 SESIÓN'},
        {c0:'ID',c1:'2 SESIONES'},        
        {c0:'ID',c1:'1 MES'},
        {c0:'ID',c1:'2 MESES'},
        {c0:'ID',c1:'3 MESES'},
        {c0:'ID',c1:'4 MESES'},
        {c0:'ID',c1:'5 MESES'},
        {c0:'ID',c1:'6 MESES'},
        {c0:'ID',c1:'TODO'}
      ],      
      listaCursos:[],
      listaHorarios:[],
      listaMensajes:[
        {
          c0:'IDMENSAJE',
          c1:'titulo',
          c2:'fecha',
          c3:'maestro',
          c4:'nombre curso',
          c5:'contenido',
          c6:0,
        }
      ]
    }
  },
  methods:{    

    toHome(){
        this.userStore.login=0;
        this.$root.changeRoute("/");
    },


    getListaConstanciasAlumno(){
      console.log("getListaConstanciasAlumno()");      
      let data = {
        id:''+this.userStore.idAlCur,
      };
      console.log("data: ");
      console.log(data);
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getListaConstanciasAlumno/",
        data,
        this.onSuccessgetListaConstanciasAlumno
      );
    },
    onSuccessgetListaConstanciasAlumno(data){
      console.log("onSuccessgetListaConstanciasAlumno:");
      console.log(data);
      this.setListaCursos(data.data.data);
      //this.$root.importData(data);      
    },
    setListaCursos(data){

      this.listaCursos=data;

    },

    
    setSelectedCurso(curso){

      this.userStore.selectedCursoInscripcion=curso;
      this.showVistaCurso=true;

    },

    setModalidad(modalidad){
      this.modalidad=modalidad;
      this.mode=0;
    },

    setPlantel(plantel){
      this.plantel=plantel;
      //window.scrollTo(0,100);
      this.mode=0;
    },
    setCategoria(categoria){
      this.categoria=categoria;
      this.mode=0;
    },
    setDuracion(duracion){
      this.duracion=duracion;
      this.mode=0;
    },

    getBtnColorClass(index){

      let i=(index)%6+1;
      
      let style;
      switch(i){

        case 1:
          style=`
          gradientColor1
          `;
        break;

        case 2:
          style=`
          gradientColor2
          `;
        break;

        case 3:
          style=`
          gradientColor3
          `;
        break;

        case 4:
          style=`
          gradientColor4
          `;
        break;

        case 5:
          style=`
          gradientColor5
          `;
        break;

        case 6:
          style=`
          gradientColor6
          `;
        break;
        

      }

      console.log("gradiente: "+i);
      return style;

    },

    filtrarListaPorPlantel(data,plantel){
      let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        if(data[i].c3==plantel){
          arr[arr.length]=data[i];
        }
      }
      return arr;
    },
    filtrarListaPorCategoria(data,categoria){
      let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        if(data[i].c4==categoria){
          arr[arr.length]=data[i];
        }
      }
      return arr;
    },
    filtrarListaPorModalidad(data,modalidad){
      let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        if(data[i].c5==modalidad){
          arr[arr.length]=data[i];
        }
      }
      return arr;
    },
    filtrarListaPorDuracion(data,duracion){
      let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        if(data[i].c18==duracion){
          arr[arr.length]=data[i];
        }
      }
      return arr;
    },

    filtrarListaPorBusqueda(data,busqueda){
    let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        //console.log("buscando en:");
        //console.log(data[i].c1);
        //data[i].c1.toUpperCase().indexOf(busqueda.toUpperCase())!=-1
        if(this.containsSubstring(data[i].c0,busqueda)){
          arr[arr.length]=data[i];
        }
        
      }

      console.log("arreglo en filtro busqueda:");
      console.log(arr);

      return arr;
    },
    containsSubstring(strOriginal,searchingStr){

      let strOrig = ''+strOriginal.toLowerCase();
      let searchStr = ''+searchingStr.toLowerCase();

      strOrig = strOrig.replace(/á/g, "a");
      strOrig = strOrig.replace(/é/g, "é");
      strOrig = strOrig.replace(/í/g, "i");
      strOrig = strOrig.replace(/ó/g, "o");
      strOrig = strOrig.replace(/ú/g, "u");

      searchStr = searchStr.replace(/á/g, "a");
      searchStr = searchStr.replace(/é/g, "é");
      searchStr = searchStr.replace(/í/g, "i");
      searchStr = searchStr.replace(/ó/g, "o");
      searchStr = searchStr.replace(/ú/g, "u");


      console.log("comparando: "+strOrig+" = "+searchStr);
      return (strOrig.indexOf(searchStr)!=-1);


    },


  },
  computed:{
    ...mapStores(useUserStore),
    filtroCursos(){
      let arr=this.listaCursos;
      //console.log("Cursos:");
      //console.log(arr);
      /*
      if(this.plantel!=''){
        arr=this.filtrarListaPorPlantel(arr,this.plantel);
      }*/
      //console.log("plantel:");
      //console.log(arr);
      /*
      if(this.categoria!=''){
        arr=this.filtrarListaPorCategoria(arr,this.categoria);
      }
      */
      //console.log("Categoría:");
      //console.log(arr);
      /*
      if(this.modalidad!=''){
        arr=this.filtrarListaPorModalidad(arr,this.modalidad);
      }
      */
      //console.log("Modalidad:");
      //console.log(arr);
      /*
      if(this.duracion!=''&&this.duracion!='TODO'){
        arr=this.filtrarListaPorDuracion(arr,this.duracion);
      }
      */
      //console.log("Duración:");
      //console.log(arr);

      if(this.busqueda!=''){
        console.log("Filtro por Busqueda");
        arr=this.filtrarListaPorBusqueda(arr,this.busqueda);
      }
      //console.log("Búsqueda:");
      //console.log(arr);

      //this.nCursos=arr.length;
      return arr;
    }
    
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .btnBelleza:hover,
  .btnContabilidad:hover,
  .btnGastronomia:hover,
  .btnModas:hover,
  .btnBienestar:hover,
  .btnCiencias:hover,
  .btnAdmision:hover,
  .btnIdiomas:hover,
  .btnDiseno:hover,
  .btnInformatica:hover,
  .btnSustentabilidad:hover,
  .btnPresencial:hover,
  .btnLinea:hover,
  .btnXalapa:hover,
  .btnVeracruz:hover,
  .btnVillahermosa:hover,
  .btnCalendario:hover
  {
    transform: scale(0.96);
  }
  .btnCalendario{
    color:white;
    overflow: hidden;
  }
  .calendarioOverlay{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/calendario.png') center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .btnPresencial{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/presencial.png') center;
    background-color: var(--blueOriginal); 
    color:transparent; 
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnLinea{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/linea.png') center;  
    background-color: var(--orange);  
    color:transparent;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnXalapa{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/xalapa.png') center;  
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnVeracruz{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/veracruz.png') center;  
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnVillahermosa{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/villahermosa.png') center;  
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnBelleza{  
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/belleza.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;

  }

  .btnContabilidad{      
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/contabilidad.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }
  .btnGastronomia{  
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/gastronomia.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnModas{  
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/modas.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnBienestar{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/bienestar.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnCiencias{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/ciencias.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnAdmision{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/examen.png') center; 
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnIdiomas{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/idiomas.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnDiseno{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/disenio.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnInformatica{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/informatica.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }


  .btnMatematicas{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/matematicas.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }


  .btnSustentabilidad{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/sustentabilidad.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnCuidadoPersonal{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/cuidadoPersonal.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnManualidades{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/manualidades.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .glassCard{
    
    background: rgba(255,255,255,0.80);    
    backdrop-filter: blur(2px);
    box-shadow: 0 8px 32px 0 rgba(31,38,135,0.37);    
    margin: 0 auto;
    border-radius: 10px;
    padding:3px;
    border: 1px solid rgba(255,255,255,0.18);
  
  }


  .cursoBtn-inner{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .cursoBtn-front, .cursoBtn-back {    
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .cursoBtn-back{
    background:var(--blueDark2);
    transform: rotateY(180deg);
  }


  .cursoBtn:hover .cursoBtn-inner{
    transform: rotateY(180deg);
  }

  @media (max-width: 2000px){

    .cursoContainer
    {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        column-gap: 5px;
        row-gap: 5px;
    }

    .cursoBtn{
        border-radius: 5px;        
        color:white;
        width: 18vw;
    }

  }

  @media (max-width: 550px){

    .cursoContainer
    {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 5px;
        row-gap: 5px;
    }

    .cursoBtn{
        border-radius: 5px;
        
        color:white;
        width: 45vw;
    }

  }


  @media (max-width: 350px) {

    .cursoContainer
    {
        display: grid;
        grid-template-columns: auto;
        column-gap: 5px;
        row-gap: 5px;
    }

    .cursoBtn{
        border-radius: 5px;
        
        color:white;
        width: 80vw;
    }

  }

  


  

</style>
