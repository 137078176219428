<template>
  <div class="fullWidth" style="color:black;">

    <div class="col49 center colSm95">
      <div class="formRow col60 center colSm95">
            
        <div class="formCol col95 colSm95 tCenter" style="margin-top:10px;">
          <div>
            <div class="col50 row center">
              <img src="img/usuario.png" class="col50 row center" style="vertical-align:middle;">
            </div>
            <div class="col95 center">
              <h3 style="font-family: town;vertical-align:middle;color:var(--orange);">
                DESCARGA Y VERIFICACIÓN DE CONSTANCIAS
              </h3>
            </div>
          </div>
          <div class="formRow colSm95" v-if="!showValidar">
            
            <input-text ref="curpRef" :propName="'curp'"  :whiteBackground="false" :mode="'curp'" 
            :placeholder="'CURP'" :capital="true" :maxLength="18" :minLength="18"></input-text>
          </div>
        </div>

        <div class="formCol col95 colSm95" v-if="!showValidar">
          <button class="btnBlue col95 center" v-on:click="sendLogin()"                
          >
            <i class="bi bi-person-circle"></i>
            Buscar
          </button>
          <button class="btnOrange col95 center" v-on:click="showValidar=true" style="margin-top:5px;"
          >
            <i class="bi bi-search"></i>
            Validar Constancia
          </button>
          
        </div>

        


        <div class="formCol col95 colSm95" v-else>
          <div class="formRow colSm95">            
            <input-text ref="folioRef" :propName="'folio'"  :whiteBackground="false" :mode="'AN'" 
            :placeholder="'Folio de Constancia'" :capital="false" :maxLength="15" :minLength="15"></input-text>            
          </div>
          <button class="btnBlue col95 center" v-on:click="sendFolio()"                
          >
            <i class="bi bi-search"></i>
            Validar Constancia
          </button>
          <div class="col95 center tCenter" style="cursor:pointer;color:var(--blue);margin-top:10px;" @click="showValidar=false;">
            <strong style="border-bottom:var(--blue) solid 1px;">
              <i class="bi bi-arrow-left"></i>
              Regresar
            </strong>
          </div>
        </div>
                            
      </div>
    </div>


    <modal title="Descarga y Validación de Constancias" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">
          {{modalMessage}}
        </h3>

        <div v-if="showConstanciaValida">

          <div>

            <strong style="font-size:70px;color:var(--correct);">
              <i class="bi bi-bookmark-check-fill"></i>
            </strong>

          </div>

          <div>
            Nombre:
            <strong>
              {{nombreAl}}
            </strong>
          </div>
          <div>
            CURP:
            <strong>
              {{curp}}
            </strong>
          </div>
          <CartaCursoInscrito :cursoData="cursoData">
          </CartaCursoInscrito>
        </div>

      </div>
    </modal>

  </div>
</template>

<script>

import { mapStores } from 'pinia';
import { useUserStore } from '../store/user';
import InputText from './Inputs/InputText.vue';
import CartaCursoInscrito from './Home/CartaCursoInscrito.vue';


import Modal from './Modal/Modal.vue';


export default {
  name: 'RegistroCuentaAlumno',
  components:{
    Modal,
    InputText,
    CartaCursoInscrito
  },
  mounted:function(){

    this.confirmado=false;
    this.userStore.currentView='REGISTRO';
    this.getCurrentVersion();

  },
  data:function(){
    return{

      showValidar:false,
      currentVersion:'',
      verMsg:'',
      alumnoData:{},
      confirmado:false,
      showModalPrivacidad:false,
      showModal:false,
      showNuevoRegistro:false,
      modalMessage:'',
      procesando:0,
      modalidad:'',
      cursoData:null,
      showConstanciaValida:false,
      nombreAl:'',
      curp:'',
      
      listaCursos:[],
      listaHorarios:[],      
      formData:{
        'idConstancia':{data:"",state:0},
        'curp':{data:"",state:0},
        'folio':{data:"",state:0},
      },
    };    
  },
  methods:{

    getCurrentVersion(){

      let data={};

      console.log("data:");
      console.log(data);
      
      this.$root.sendPOSTRequest(
      "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/verificarVersionPortalCursos/",
      data,
      this.onSuccessVersion,
      this.onErrorVersion);

    },
    onSuccessVersion(data){

      console.log(data);
      
      this.userStore.showLogin=(data.acceso=='1');
      this.userStore.showRegistro=(data.registro=='1');
      this.setVersionState(data.version);

    },
    onErrorVersion(error){
      console.log(error);

    },

    setVersionState(currentVersion){
      console.log('currentVersion:');
      console.log(currentVersion);
      let version=currentVersion;

        //localStorage.clear();
        if(localStorage.estado){
            let estadoLoaded=JSON.parse(localStorage.estado);
            //this.version=estadoLoaded.version;
            //this.versionActual=version;
            //alert("Version actual: "+estadoLoaded.version);
            console.log("Estado:");
            console.log(localStorage.estado);
            console.log("estadoLoaded.version="+estadoLoaded.version);
            console.log("currentVersion="+currentVersion);

            if(estadoLoaded.version!=version){
              let estado={
                
                version:""+version,
                actualizado:true
                
              };
              
              localStorage.estado=JSON.stringify(estado);
              //this.userData.version=currentVersion;
              //alert("Estado Actualizado");
              this.verMsg="Versión Actualizada: "+this.currentVersion;
              console.log("Versión Actualizada");
              //alert("Versión Actualizada");
              console.log(localStorage.estado);
              location.reload(true);
            }
            this.currentVersion=currentVersion;

        }else{
          let estado={            
            version:""+currentVersion,
            actualizado:true
          };
          //this.userData.version=currentVersion;
          localStorage.estado=JSON.stringify(estado);
          //alert("Estado Guardado");
          this.verMsg="Versión guardada";
          console.log(localStorage.estado);
          this.currentVersion=currentVersion;
        }

    },

    closeModal(){
      this.showModal = false;
    },
    sendLogin(){
      console.log("sendLogin()");
      //let passw = this.$root.genPSW(8);
      let data = {
        curp:''+this.formData.curp.data,
      };
      console.log("data: ");
      console.log(data);
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getCURPVerificado/",
        data,
        this.onSuccessSendLogin
      );
    },
    onSuccessSendLogin(data){
      console.log("onSuccessSendLogin:");
      console.log(data.data.data);      

      if(data.data.data.c0==1){                
        this.$root.importData(data.data.data);
      }else{
        this.showModal = true;
        this.modalMessage = "Hubo un error al recuperar la información de su CURP, por favor verfique que este escrita correctamente.";
      }
      /*if(data.data!==null){
        
      }
      */
      
    },

    sendFolio(){
      console.log("sendFolio()");
      //let passw = this.$root.genPSW(8);
      let data = {
        folio:''+this.formData.folio.data,
      };
      console.log("data: ");
      console.log(data);
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getFolioVerificado/",
        data,
        this.onSuccessSendFolio
      );
    },
    onSuccessSendFolio(data){
      console.log("onSuccessSendLogin:");
      console.log(data.data);
      
      if(data.data.c0==1){
        this.showModal = true;
        this.modalMessage = "Constancia Válida";

        let cursoData={
          c0:''+data.data.c7,
          c1:''+data.data.c8,
          c2:''+data.data.c9,
          c3:''+data.data.c10,
          c4:''+data.data.c11,
          c5:''+data.data.c12,
          c6:''+data.data.c13,
          c7:''+data.data.c14,
          c8:''+data.data.c15
        };        

        this.nombreAl = data.data.c4+' '+data.data.c5+' '+data.data.c6;
        this.curp = data.data.c2;
        this.cursoData=cursoData;
        this.showConstanciaValida=true;

      }else{
        this.showModal = true;
        this.modalMessage = "Hubo un error al recuperar la información de su CURP, por favor verfique que este escrita correctamente.";
      }
      
      
    },

  },
  computed:{
    ...mapStores(useUserStore),
    
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.glassModulo{
    
    z-index:100;  
    
    background: rgba(255,255,255,0.30);    
    backdrop-filter: blur(2px);
    box-shadow: 0 10px 32px 0 rgba(31,38,135,0.37);    
    margin: 0 auto;
    margin-bottom: 0px;
    border-radius: 10px;
    padding:5px;
    border: 1px solid rgba(255,255,255,0.18);
    
  }

</style>
