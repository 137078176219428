import {createRouter,createWebHashHistory} from "vue-router";

import Login from './components/Login.vue';
import Home from './components/Home/Home.vue';

import NotFound from './components/NotFound.vue';


const routes = [
    { path: '/', component: Login },
    { path: '/home', component: Home },
    {
      path: "/:catchAll(.*)",
      component: NotFound,
    },
  ];

const router = createRouter({    
    history: createWebHashHistory(),
    routes,    
});

export default router;