import { createApp } from 'vue';
import {createPinia} from 'pinia';
import router from './router.js';
import App from './App.vue';
/*
import Vuex from "vuex";
import { store } from './store/store';
*/



const app = createApp(App);


app.use(router);
app.use(createPinia());
//app.use(Vuex);
//app.use(store);

app.mount('#app');
//console.log("App: ");
//console.log(app);