<template>
  <div class="navBar blueOriginal" style="position:fixed;top:0;">
    <div class="fullWidth" style="background:none;">

      <div class="col27 row" @click="toHome()" style="background:red;">
        <img class="iconBrand" src="./../../../public/img/logoObscuro.png" style="margin-top:-5px;position:absolute;left:0;z-index:100;">
      </div>
      
      <div class="col35 row tLeft" style="background:none;">
        <div class="fullWidth block tCenter" style="background:none;">
          <h3 class="navTitle" style="margin-bottom:3px;margin-top:10px;">
            Portal de Cursos 
          </h3>
        </div>
        <div class="block tCenter" style="padding-bottom:10px;">
          <h3 class="navTitle" style="margin-top:3px;">
            &nbsp;LAO    
          </h3>
        </div>

      </div>

      
    </div>
  </div>
</template>

<script>
  
  import { mapStores } from 'pinia'
  import { useUserStore } from '../../store/user';
  


  export default {
  
    name: 'Navbar',
    props: {
      msg: String
    },
    data:function(){

      return{
        optionMode:''


      }

    },
    methods:{

      toNuevoRegistro(){

        this.$root.changeRoute("/nuevoAlumno");

      },

      eliminarCursoDeCarrito(curso){

        let l = this.userStore.carritoCursos.length;

        for(let i=0;i<l;i++){

          if(this.userStore.carritoCursos[i].c0==curso.c0){

            this.userStore.carritoCursos.splice(i,1);

          }

        }

      },

      toAlumnoHome(){
        if(this.userStore.plantel==''){

          this.userStore.plantel='XALAPA';
        }
        this.$root.changeRoute("/home");
      },

      toLogin(){
        if(this.userStore.plantel==''){

          this.userStore.plantel='XALAPA';
        }
        this.$root.changeRoute("/inicio");
      },

      toHome(){
        this.userStore.login=0;
        this.$root.changeRoute("/");
      }

    },
    computed:{
    
    ...mapStores(useUserStore)

    },
    
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.glassCarrito{
    
    background: rgba(255,255,255,0.30);    
    backdrop-filter: blur(2px);
    box-shadow: 0 8px 32px 0 rgba(31,38,135,0.37);
    
    margin: 0 auto;
    border-radius: 10px;
    padding:10px;    
    border: 1px solid rgba(255,255,255,0.18);
  
  }


</style>
