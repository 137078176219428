<template>
  <div class="CartaCurso col95 colSm90 row" style="margin: 15px;font-size:1rem;">
    
    <div class=" block tCenter glassCard" style="width:100%;line-height:40px;margin-top:10px;">
      
      <div class="orange fullWidth" style="width:100%;" v-if="cursoData.c6=='EN LÍNEA'">
        {{cursoData.c1}} - {{cursoData.c6}}
      </div>
      <div class="primary fullWidth" style="width:100%;" v-if="cursoData.c6=='PRESENCIAL'">
        {{cursoData.c1}} - {{cursoData.c6}}
      </div>

      <div class="col20 row tLeft" 
      style="margin:0 auto;overflow:hidden;">
        <img :src="cursoData.c8" style="object-fit:cover;height:130px;width:250px;">
      </div>

      <div class="col80 row tLeft" style="margin:0 auto;margin-top:0px;">
        <div class="col70 row" style="min-height:25vh;">          
          <div>
            <div>
              <h4 style="color:var(--blueDark);margin-bottom:0px;margin-top:0px;">
                {{cursoData.c0}}
                
              </h4>
            </div>
            
            <div style="line-height:1;margin-bottom:5px;">
              <p>
                <strong>
                  Total de Horas: {{cursoData.c5}}
                </strong>
              </p>
              <p>
                <small style="color:gray;">
                  <i class="bi bi-bookmark-star"></i>
                  {{$root.fechaToStr(cursoData.c4)}}
                </small>
              </p>
            </div>
          </div>

        </div>
        <div class="col30 row">          
          
          <div class="btn btnBlue fullWidth row" @click="getConstancia(cursoData.c7)">
            <i class="bi bi-download"></i>Descargar Constancia
          </div>

          

        </div>

      </div>

      <!--
      <div class="formRow col90 center glass tCenter">
    
        <div class="formRow colSm95" v-if="procesando==0">
          
          <button class="btn primary btnWide" 
          >Registrar</button>
                
        </div>
        
      </div>
      -->

    </div>

    <modal title="Oferta de Cursos" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">

          {{modalMessage}}
          
        </h3>
        
      </div>
      
    </modal>


  </div>
</template>

<script>
/*


import { useUserStore } from '../../store/user';
import InputText from '../Inputs/InputText.vue';
import InputSelect from '../Inputs/InputSelect.vue';
*/
import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
import Modal from '../Modal/Modal.vue';


export default {
  name: 'CartaCursoInscrito',
  components:{Modal},
  props:{
    cursoData:Object
  },
  data:function(){
    return{
      showModal:false,
      modalMessage:'',
      
    }
  },
  methods:{


    getConstancia(key){

      let link='https://www.sitioliceo.com/scLCcur/api/superSC/v1/constancia.php?id='+key;

      window.open(link);

    },

    setSelected(){

      this.$emit('setSelectedCursoEv');

    }


  },
  computed:{
    ...mapStores(useUserStore),
  }
  
  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@keyframes jello {
  11.1% {
    transform: none
  }
  22.2% {
    transform: skewX(-5.5deg) skewY(-5.5deg)
  }
  33.3% {
    transform: skewX(4.25deg) skewY(4.25deg)
  }
  44.4% {
    transform: skewX(-2.525deg) skewY(-2.525deg)
  }
  55.5% {
    transform: skewX(1.3625deg) skewY(1.3625deg)
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg)
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg)
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
  }
  100% {
    transform: none
  }
}


@keyframes jello2 {
  11.1% {
    transform: none
  }
  22.2% {
    transform: skewX(-5.5deg) skewY(-5.5deg)
  }
  
  33.4% {
    transform: skewX(-2.525deg) skewY(-2.525deg)
  }
  55.5% {
    transform: skewX(1.3625deg) skewY(1.3625deg)
  }
  
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg)
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
  }
  100% {
    transform: none
  }
}


.center {
  display: inline-block
}

.jello {
  animation: jello 1.5s ease;
  transform-origin: center
}

.CartaCurso{
  color:black;
  transition: all 0.5s ease;
  transform: scale(0.96);
}

.CartaCurso:hover {
  transform: scale(1);
  /*animation: jello2 0.6s ease-in;*/
  transform-origin: center;
  
  
}

.glassCard{
    
    background: rgba(255,255,255,0.30);    
    backdrop-filter: blur(2px);
    box-shadow: 0 8px 32px 0 rgba(31,38,135,0.37);    
    margin: 0 auto;
    border-radius: 10px;
    padding:10px;    
    border: 1px solid rgba(255,255,255,0.18);
  
  }


</style>
